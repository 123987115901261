import {breakpoints, gridGutter } from "@olmokit/core/scss";
import { $ } from "@olmokit/dom";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  AutoHeight,
  Swipe,
} from "@olmokit/core/glide";
// import "@olmokit/core/glide/bullets";
// import "@olmokit/core/glide/arrows";
import "components/Card/Collection";
import "./Collection.scss";

/**
* Component: SliderCollection
*/
export default function sliderCollection(rootSelector) {
  
  const $container = `${rootSelector ? rootSelector + " " : ""}`;
  const slider = glide(
    $container,
    {
      loop: true,
      autoplay: 4500,
      hoverpause: true,
      animationDuration: 900,
      killWhen: { above: 99999 },
      bound: true,
      autoHeight: false, // if true, remove {align-items: center;} from .glide__slides
      // breakpoints: {
      //   [breakpoints.over]: {
      //     perView: 1,
      //     gap: gridGutter,
      //     peek: { before: 0, after: 0 },
      //   },
      //   [2560]: {
      //     perView: 1,
      //     gap: gridGutter,
      //     peek: { before: 0, after: 0 },
      //   },
      //   [1920]: {
      //     perView: 1,
      //     gap: gridGutter,
      //     peek: { before: 0, after: 0 },
      //   },
      //   [1680]: {
      //     perView: 1,
      //     gap: gridGutter,
      //     peek: { before: 0, after: 0 },
      //   },
      //   [breakpoints.xxl]: {
      //     perView: 1,
      //     gap: gridGutter,
      //     peek: { before: 0, after: 0 },
      //   },
      //   [breakpoints.xl]: {
      //     perView: 1,
      //     gap: gridGutter,
      //     peek: { before: 0, after: 0 },
      //   },
      //   [breakpoints.lg]: {
      //     perView: 1,
      //     gap: gridGutter,
      //     peek: { before: 0, after: 0 },
      //   },
      //   [breakpoints.md]: {
      //     perView: 1,
      //     gap: gridGutter,
      //     peek: { before: 0, after: 0 },
      //   },
      //   [breakpoints.sm]: {
      //     perView: 1,
      //     gap: gridGutter,
      //     peek: { before: 0, after: 0 },
      //   },
      //   [480]: {
      //     perView: 1,
      //     gap: gridGutter,
      //     peek: { before: 0, after: 0 },
      //   },
      // },
      perView: 1,
    },
    { 
      Anchors, 
      Autoplay, 
      Breakpoints, 
      Controls,
      AutoHeight,
      Swipe }
      );
      
      return slider;
      
    }
