import { breakpoints, gridGutter } from "@olmokit/core/scss";
import { $ } from "@olmokit/dom";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  AutoHeight,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/bullets";
import "@olmokit/core/glide/arrows";
import "components/Card/Product";
import "./Product.scss";

/**
* Component: SliderProduct
*/
export default function sliderProduct(rootSelector) {
  
  const $container = `${rootSelector ? rootSelector + " " : ""}`;
  const slider = glide(
    $container,
    {
      loop: true,
      autoplay: 4500,
      hoverpause: true,
      animationDuration: 900,
      killWhen: { above: 99999 },
      bound: true,
      autoHeight: false, // if true, remove {align-items: center;} from .glide__slides
      breakpoints: {
        [breakpoints.over]: {
          perView: 3,
          gap: gridGutter,
          peek: { before: 0, after: 0 },
        },
        [2560]: {
          perView: 3,
          gap: gridGutter,
          peek: { before: 0, after: 0 },
        },
        [1920]: {
          perView: 2,
          gap: gridGutter,
          peek: { before: 0, after: gridGutter * 8 },
        },
        [breakpoints.xxl]: {
          perView: 2,
          gap: gridGutter,
          peek: { before: gridGutter * .5, after: gridGutter * 8 },
        },
        [breakpoints.lg]: {
          perView: 2,
          gap: gridGutter,
          peek: { before: gridGutter * .5, after: gridGutter * 6 },
        },
        [breakpoints.md]: {
          perView: 1,
          gap: gridGutter,
          peek: { before: gridGutter * .5, after: gridGutter * 6 },
        },
        [480]: {
          perView: 1,
          gap: gridGutter,
          peek: { before: gridGutter * .5, after: gridGutter * 2},
        },
      },
    },
    { Anchors, 
      Autoplay, 
      Breakpoints, 
      Controls,
      AutoHeight,
      Swipe }
      );
      
      return slider;
      
    }
