import { $, on, addClass, removeClass } from "@olmokit/dom";
import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
* Component: Inspire
*/

const btnInsp = $(".Inspire:");
const closeInsp = $(".Inspire:close");

export function Inspire() {
  console.log("Inspire mounted.");
  
  if (btnInsp) {
    if (!sessionStorage.hasOwnProperty("closeinsp")) {
      addClass(btnInsp, "is-open");
    } 
    on(closeInsp, 'click', () => {
      removeClass(btnInsp, "is-open");
      sessionStorage.setItem("closeinsp", "true");
    });
  }
  
}
