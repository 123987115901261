import { $, on } from "@olmokit/dom";
import gsap from "gsap";
import Search from "./Search";
import "./InputMenu.scss";

/**
 * Component: Search
 */

export function closeCleanSearch() {
  // const $searchopen = $('.Search:open');
  // const $searchclose = $('.Search:close');
  // const $searchWrap = $('.Searchbarheader:wrap');
  const $searchbarheader = $('#searchbarheader');

  // $searchopen.style.display = 'block';
  // $searchclose.style.display = 'none';
  // gsap.to($searchWrap, { duration: 0.1, width: 0 });
  $searchbarheader.value = '';
}

export default function InputMenu() {
  console.log("InputMenu mounted.");

  const $searchopen = $('.Search:open');
  const $searchclose = $('.Search:close');
  const $searchWrap = $('.Searchbarheader:wrap');
  const $searchbarheader = $('#searchbarheader');
  const $searchbarheaderbutton = $('.Searchbarheader:wrap');
  const $searchbarheaderinput = $('.Searchbarheader:input');

  on($searchbarheaderbutton, 'mouseenter', () => {
    gsap.to($searchbarheaderinput, { duration: 0.3, width: 240 });
  });
  on($searchbarheaderbutton, 'mouseleave', () => {
    gsap.to($searchbarheaderinput, { duration: 0.3, width: 0 });
  });

  on($searchopen, 'click', () => {
    $searchopen.style.display = 'none';
    $searchclose.style.display = 'block';
    gsap.to($searchWrap, { duration: 0.1, width: 400 });
    $searchbarheader.focus();
  });

  on($searchclose, 'click', () => {
    $searchopen.style.display = 'block';
    $searchclose.style.display = 'none';
    gsap.to($searchWrap, { duration: 0.1, width: 0 });
  });

  Search('#searchbarheader', '#buttonsearchbarheader');

}

