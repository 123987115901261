import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
 * Component: FullVideo
 */
export function FullVideo() {
  console.log("FullVideo mounted.");

  import("@olmokit/core/player").then(({ Player }) => {
    Player();    
  });
}
