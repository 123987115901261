import { $, on, addClass, removeClass } from "@olmokit/dom";
import {breakpoints } from "@olmokit/core/scss";
import BaseHeader from "@olmokit/components/Header";
import { scrollThreshold } from "@olmokit/core/scroll/threshold";
import { Selectlang } from "components/Selectlang";
import scrollLock from "@olmokit/core/scroll/lock";
import { InputMenu } from "components/Search";
import "components/Mmenulight";
import "components/Hamburger";
import "utils/mmenulight/mmenu-light";
import "utils/mmenulight/mmenu-light.polyfills";
import "utils/mmenulight/mmenu-light.scss";

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import "./index.scss";

/**
 * Component: Header
 *
 */
export function Header() {
  const header = BaseHeader();

  const search = $(".Header:search");
  if (search && window.innerWidth >= breakpoints.lg) {
    InputMenu();
  }

  Selectlang();

  scrollThreshold(55, { target: header.$root });

  // Flyout menu
  const h = $('.Header:.is-dark');
  const kbtn = $('#kitchensbtn');
  const kcll = $('#kitchenscollapse');
  on(kbtn, 'click', (e) => {
    if (!e.target.classList.contains('is-in')) {
      openMenu(h, kbtn, kcll);
    } else {
      closeMenu(h, kbtn, kcll);
    }
  });
  document.addEventListener('keydown', evt => {
    if (evt.key === 'Escape') {
      closeMenu(h, kbtn, kcll);
    }
  });

  // Mobile menu
  var mmenu = new MmenuLight(
    document.querySelector("#mmenu"),
    "(max-width: 991px)"
  );
  var navigator = mmenu.navigation({
    selectedClass: "Selected",
    slidingSubmenus: true,
    theme: "light",
    title: "MENU"
  });
  var drawer = mmenu.offcanvas({
    position: "left"
  });
  document.querySelector('a[href="#mmenu"]').addEventListener("click", evnt => {
    evnt.preventDefault();
    drawer.open();
  });

  return {
    /**
     * Set variant
     *
     * @param {"light" | "dark"} variant
     */
    setVariant(variant) {
      const { className } = header.$root;
      header.$root.className = className.replace(
        /(is-).+(\s*)/,
        `$1${variant}$2`
      );
    },
  };

}

// Flyout menu functions
function openMenu(header, btn, container) {
  scrollLock.disable(document.body);
  removeClass(header, "is-dark");
  addClass(header, "is-light");
  addClass(btn, "is-in");
  addClass(container, "is-in");
  let headerBelow = $(".Header:.threshold--below");
  if (headerBelow) {
    headerBelow.classList.remove('threshold--below');
    headerBelow.classList.add('threshold--above');
  }
}

function closeMenu(header, btn, container) {
  scrollLock.enable(document.body);
  removeClass(header, "is-light");
  addClass(header, "is-dark");
  removeClass(btn, "is-in");
  removeClass(container, "is-in");
  let headerAbove = $(".Header:.threshold--above");
  if (headerAbove) {
    headerAbove.classList.remove('threshold--above');
    headerAbove.classList.add('threshold--below');
  }
}

// Scrolling header
const showAnim = gsap
  .from(".HeaderUpDown", {
    top: -56,
    paused: true,
    duration: 0.3,
  })
  .progress(1);

ScrollTrigger.create({
  start: "top top",
  end: 99999,
  onUpdate: (self) => {
    self.direction === -1 ? showAnim.play() : showAnim.reverse();
  },
});
