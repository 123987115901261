/**
 * @file Route: home
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */

import "layouts/main";
import { $ } from "@olmokit/dom";
import { Inspire } from "components/Inspire";
import { FullVideo } from "components/Full/Video";
import "components/Full/Image";
import "components/Title";
import "components/Gallery";
import sliderProduct from "components/Slider/Product";
import sliderCollection from "components/Slider/Collection";
import "components/Card/Blog";
import "components/ActionBar";
import "./index.scss";

console.log("Route home/index.js mounted.");

Inspire();
FullVideo();
const glideEl = $(".SliderProduct:slider");
if (glideEl) {
    sliderProduct('.SliderProduct:slider');
}
const collEl = $(".SliderCollection:slider");
if (collEl) {
    sliderCollection('.SliderCollection:slider');
}
